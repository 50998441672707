<template>
  <div class="container flex-column p_relative">
    <div class="header p_sticky">
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <img :src="require('../assets/logo.jpg')" class="logo" />
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li
              v-for="(item, index) in links"
              :key="index"
              :class="{ active: item.active }"
            >
              <router-link
                :to="item.url"
                :class="{ active: item.url === '/knowledge' }"
                >{{ item.name }}</router-link
              >
            </li>
          </ul>
         <Avatar style="margin-right: 37px;" />
        </div>
      </div>
    </div>

    <Search :enterCallBack="enterCallBack" />

    <div class="main">
      <div class="main_top">
        <!-- <router-link to="/knowledge" >知识库</router-link> /  -->
        <span>搜索内容/</span>
        <span>{{ queryText }}</span>
      </div>
      <div class="main_bottom p_relative">
        <div class="main_bottom_left">
          <div class="tab_d">
            <div @click="tabKey = 1" :class="`btn ${tabKey === 1 && 'active'}`">
              文章
            </div>
            <div @click="tabKey = 2" :class="`btn ${tabKey === 2 && 'active'}`">
              知识图谱
            </div>
          </div>
          <div class="history_search">
            <div class="history_search_top">
              <div>最近搜索</div>
              <div @click="clear">清空</div>
            </div>
            <div class="history_search_list">
              <div
                @click="toSearch(i)"
                class="history_search_item"
                v-for="(i, x) in searchList"
                :key="x"
              >
                <div>
                  <img :src="require('../pages/app/images/history.png')" />
                </div>
                {{ i }}
              </div>
            </div>
          </div>
        </div>
        <div class="main_bottom_right">
          <!-- 文章 -->
          <div class="article" v-show="tabKey === 1">
            <div class="article_top">
              一共为您找到大约{{ page.total }}个结果
            </div>
            <div class="article_list">
              <div
                @click="toDetail(i)"
                class="article_item"
                v-for="(i, x) in page.list"
                :key="x"
              >
                <div class="d-flex">
                  <div>
                    {{ i.catalogName }}
                  </div>
                </div>
                <div class="ellipsis3">
                  {{ i.catalogIntro }}
                </div>
                <div style="font-size: 0.9em; color: #999; margin-top: 1em">
                    <img :src="require('../pages/app/images/path.png')" />
                    {{ i.catalogStructure }}
                  </div>
              </div>
            </div>
            <Pagination
              :page="page"
              :topage="topage"
              :style="`justify-content: center;`"
            />
          </div>
          <!-- 知识图谱 -->
          <div class="knowGraph" v-show="tabKey === 2">
            <KnowGraph
              :list="graphList"
              :itemClick="itemClick"
              :name="queryText"
            />

            <div class="article">
              <div class="related">相关内容</div>
              <div class="article_list">
                <div
                  @click="toDetail(i)"
                  class="article_item"
                  v-for="(i, x) in page2.data"
                  :key="x"
                >
                  <div
                    class="d-flex"
                    style="border-left: 4px solid #fca307; padding-left: 8px"
                  >
                    <div>
                      {{ i.catalogName }}
                    </div>
                  </div>
                  <div class="ellipsis3">
                    {{ i.catalogIntro }}
                  </div>
                  <div style="font-size: 0.9em; color: #999; margin-top: 1em">
                    <img :src="require('../pages/app/images/path.png')" />
                    {{ i.catalogStructure }}
                  </div>
                </div>
              </div>
              <!-- <Pagination :page="page2" :topage="topage2" :style="`justify-content: center;`" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { reactive, toRefs, onMounted, watch, onUnmounted } from "vue";
import KnowGraph from "@/components/KnowGraph.vue";
import { useRoute } from "vue-router";
import { post } from "@/utils/common";
import { load, save } from "@/utils/storage";

export default {
  //   components: { KnowGraph },
  name: "KnowSearch",
  setup(props) {
    const route = useRoute();
    const state = reactive({
      graphList: [],
      tabKey: 1,
      articleData: [
        {
          label: "概念与分类",
          content:
            "贵细药材的发放称量要实行减重法。发放时，先核对原数量，再核对领取后的余数，最后核对发放的数量，无误后，当面交给领料员。领料员、仓管员均要在领料单上签名，仓管员及时填写货位卡和分类总账。贵细药材的发放称量要实行减重法。发放时，先核对原数量，再核对领取后的余数，最后核对发放的数量，无误后，当面交给领料员。领料员、仓管员均要在领料单上签名，仓管员及时填写货位卡和分类总账。",
        },
        {
          label: "概念与分类",
          content:
            "贵细药材的发放称量要实行减重法。发放时，先核对原数量，再核对领取后的余数，最后核对发放的数量，无误后，当面交给领料员。领料员、仓管员均要在领料单上签名，仓管员及时填写货位卡和分类总账。贵细药材的发放称量要实行减重法。发放时，先核对原数量，再核对领取后的余数，最后核对发放的数量，无误后，当面交给领料员。领料员、仓管员均要在领料单上签名，仓管员及时填写货位卡和分类总账。",
        },
        {
          label: "概念与分类",
          content:
            "贵细药材的发放称量要实行减重法。发放时，先核对原数量，再核对领取后的余数，最后核对发放的数量，无误后，当面交给领料员。领料员、仓管员均要在领料单上签名，仓管员及时填写货位卡和分类总账。贵细药材的发放称量要实行减重法。发放时，先核对原数量，再核对领取后的余数，最后核对发放的数量，无误后，当面交给领料员。领料员、仓管员均要在领料单上签名，仓管员及时填写货位卡和分类总账。",
        },
        {
          label: "概念与分类",
          content:
            "贵细药材的发放称量要实行减重法。发放时，先核对原数量，再核对领取后的余数，最后核对发放的数量，无误后，当面交给领料员。领料员、仓管员均要在领料单上签名，仓管员及时填写货位卡和分类总账。贵细药材的发放称量要实行减重法。发放时，先核对原数量，再核对领取后的余数，最后核对发放的数量，无误后，当面交给领料员。领料员、仓管员均要在领料单上签名，仓管员及时填写货位卡和分类总账。",
        },
      ],
      searchList: [],
      searchKeys: [
        {
          name: "中药",
        },
        {
          name: "中成药",
        },
        {
          name: "中药饮片",
        },
        {
          name: "贵细中药",
        },
      ],
      pageSize: 8,
      page: {
        total: 0,
        list: [],
        totalPage: 0,
        current: 1,
      },
      page2: {
        total: 0,
        list: [],
        totalPage: 0,
        current: 1,
      },
      itemCurrent: {},
      queryText: "",
    });

    // watch(() => state.searchList, (newList) => {
    //     console.log("watch searchList", newList);
    // })

    onMounted(() => {
      getData();
      const searchList = load("searchHisTory") || [];
      searchList.length = searchList.length > 5 ? 5 : searchList.length;
      state.searchList = [...new Set(searchList)];
    });

    onUnmounted(() => {
      // save("searchVal", '');
    });

    const toDetail = (i) => {
      window.open(
        `#/know_detail?id=${i.id}&knowledgeBaseId=${i.knowledgeBaseId}`
      );
    };

    const getData = () => {
      const queryText = (load("searchVal") || "").trim();
      if (queryText) {
        state.queryText = queryText;
        loadpage();
        getGraph();
        getGraph2();
      }
    };

    const clear = () => {
      save("searchHisTory", []);
      state.searchList = [];
    };

    const toSearch = (i) => {
      state.queryText = i;
      reset();
      loadpage();
      getGraph();
      getGraph2();
      state.itemCurrent = {};
    };

    const reset = () => {
      // state.tabKey = 1;
      state.page = {
        total: 0,
        list: [],
        totalPage: 0,
        current: 1,
      };

      state.page2 = {
        total: 0,
        list: [],
        totalPage: 0,
        current: 1,
      };
    };

    const getGraph = () => {
      post(
        `knowledgeBase/querySimilarityByUserInWeb`,
        {
          pageSize: 8,
          page: 1,
          queryText: state.queryText,
        },
        (data, res) => {
          console.log("getGraph ===>", data, res);
          state.graphList = data.list || [];
        }
      );
    };

    const getGraph2 = () => {
      post(
        `knowledgeBase/querySimilarityByUserInWeb`,
        {
          pageSize: 8,
          page: 2,
          queryText: state.queryText,
        },
        (data, res) => {
          console.log("getGraph ===>", data, res);
          state.page2 = { ...state.page2, data: data.list || [] };
        }
      );
    };

    const loadpage = () => {
      post(
        `knowledgeBase/querySimilarityByUserInWeb`,
        {
          ...state.page,
          pageSize: state.pageSize,
          page: state.page.current,
          queryText: state.queryText,
        },
        (data, res) => {
          const page = data || {};
          page.total =
            state.page.current === 1 ? page.total || 0 : state.page.total;
          page.totalPage = Math.ceil(page.total / state.pageSize);
          page.current = state.page.current;
          page.start = Math.max(page.current - 2, 1);
          page.end = Math.min(page.start + 4, page.totalPage);
          if (page.end - 4 < page.start) {
            page.start = Math.max(page.end - 4, 1);
          }
          state.page = page;
          console.log("state.page", state.page);
        }
      );
    };

    const tabClick = (tabKey) => {
      scrollToAnchor("graphDom");
    };

    const itemClick = (i) => {
      state.page2.data = [i];
      state.itemCurrent = i;
      // loadpage2();
    };

    const loadpage2 = () => {
      post(
        `knowledgeBase/querySimilarityByDocInWeb`,
        {
          ...state.page2,
          id: state.itemCurrent.id,
          pageSize: state.pageSize,
          page: state.page2.current,
          queryText: state.queryText,
        },
        (data, res) => {
          const page2 = data || {};
          page2.total =
            state.page2.current === 1 ? page2.total || 0 : state.page2.total;
          page2.totalPage = Math.ceil(page2.total / state.pageSize);
          page2.current = state.page2.current;
          page2.start = Math.max(page2.current - 2, 1);
          page2.end = Math.min(page2.start + 4, page2.totalPage);
          if (page2.end - 4 < page2.start) {
            page2.start = Math.max(page2.end - 4, 1);
          }
          state.page2 = page2;
          console.log("state.page2", state.page2);
        }
      );
    };

    const scrollToAnchor = (key) => {
      console.log("tab点击 ===>");
      if (key) {
        let anchorElement = document.getElementById(key);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    const enterCallBack = () => {
      const queryText = (load("searchVal") || "").trim();
      if (!queryText) return;
      state.queryText = queryText;
      reset();
      loadpage();
      getGraph();
      getGraph2();
      const list = load("searchHisTory") || [];
      const newList = [...new Set([queryText, ...list])];
      save("searchHisTory", newList);
      newList.length = newList.length > 5 ? 5 : newList.length;
      state.searchList = newList;
      state.itemCurrent = {};
    };

    const topage = (e) => {
      const to = Number(e.currentTarget.dataset.page);
      if (to !== state.page.current) {
        state.page.current = to;
        loadpage();
      }
    };

    const topage2 = (e) => {
      const to = Number(e.currentTarget.dataset.page);
      if (to !== state.page2.current) {
        state.page2.current = to;
        loadpage2();
      }
    };

    return {
      ...toRefs(state),
      toSearch,
      tabClick,
      topage,
      topage2,
      clear,
      toDetail,
      itemClick,
      enterCallBack,
    };
  },
};
</script>

<style lang="less" scoped>
@default_text_color: #666;
@default_bg_color: #fff;
@select_color: #fca307;
@default_radius: 5px;

.container {
  max-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
}

.header2 {
  padding: 0px;
  margin-top: 1rem;
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0px 2px #f0f1f2;
}

.know_header {
  max-width: 100vw;
  object-fit: cover;
}

.main {
  width: 85%;
  margin: 0 auto;
  margin-top: 1vw;
  .main_top {
    line-height: 3em;
    font-weight: bold;
    color: @default_text_color;
    padding: 0px 1vw;
    border-radius: 10px;
    overflow: hidden;
    background: @default_bg_color;

    & > span:nth-child(2) {
      color: @select_color;
    }
  }

  .active {
    background-color: @select_color !important;
    color: #fff;
  }

  .main_bottom {
    margin-top: 1vw;
    border-radius: @default_radius;
    display: flex;
    box-sizing: border-box;

    .main_bottom_left {
      position: sticky;
      top: 84px;
      width: 20vw;
      height: fit-content;
      border-radius: @default_radius;
      box-sizing: border-box;
      padding: 1vw 1vw;
      background: @default_bg_color;
      margin-right: 1vw;

      .tab_d {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);

        .btn {
          margin-bottom: 25px;
          transition: all 0.3s;
          line-height: 3em;
          padding-left: 25px;
          font-weight: bold;
          border-radius: @default_radius;
          background-color: #f2f2f2;

          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }

      .history_search {
        .history_search_top {
          font-size: 0.9em;
          display: flex;
          justify-content: space-between;
          line-height: 3em;
          color: #aaaaaa;

          & > div:nth-child(2) {
            transition: all 0.3s;

            &:hover {
              cursor: pointer;
              color: @default_text_color;
            }
          }
        }

        .history_search_list {
          .history_search_item {
            font-size: 0.9em;
            display: flex;
            align-items: center;
            line-height: 3em;
            transition: all 0.3s;

            div {
              margin-right: 10px;
            }

            &:hover {
              cursor: pointer;
              background-color: #f0f1f2;
            }
          }
        }
      }
    }

    .main_bottom_right {
      width: 1px;
      flex: 2;
      border-radius: @default_radius;
      box-sizing: border-box;

      .article {
        padding: 1vw 1vw 0px;
        box-sizing: border-box;
        background-color: #fff;
        margin-bottom: 10px;

        .related {
          font-weight: bold;
          color: #fca307;
          font-size: 1.3em;
        }

        .article_top {
          font-size: 0.9em;
          font-weight: bold;
          color: @default_text_color;
        }

        .article_list {
          .article_item {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin-top: 2.5vw;
            padding-bottom: 1.5vw;

            & > div:nth-child(1) {
              font-size: 1.1em;
              font-weight: bold;

              div {
                transition: all 0.3s;
                border-bottom: 2px solid #fff;
              }
            }

            & > div:nth-child(2) {
              transition: all 0.3s;
              margin-top: 0.8vw;
              line-height: 2em;
            //   text-indent: 2em;
              color: #666;
              font-size: 0.9em;
            }

            &:hover {
              cursor: pointer;
              & > div:nth-child(1) {
                div {
                  border-bottom: 2px solid #666;
                }
              }

              & > div:nth-child(2) {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}

.search {
  width: 100%;
  position: absolute;
  bottom: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .search_main {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .input_d {
      width: 40vw;
      height: 45px;
      border-radius: 20px;
      margin-bottom: 15px;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        padding-left: 30px;
        font-size: 17px;
      }

      input::-webkit-input-placeholder {
        color: #999;
        font-weight: bold;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999;
        font-weight: bold;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999;
        font-weight: bold;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
        font-weight: bold;
      }
    }

    .search_keys {
      bottom: -20px;
      display: flex;
      font-size: 14px;
      font-family: SimHei;
      font-weight: bold;
      color: #666666;
      padding-left: 30px;

      .search_keey_item {
        margin-right: 20px;
      }
    }
  }
}
</style>
